.intent-form {
  .valuable-specifics {
    &.other-specifics {
      .checkbox {
        height: rem-calc(38);
      }

      .buttons-fieldset {
        .button {
          &.musical-instrument {
            width: auto;
          }
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .valuable-specifics {
      &.other-specifics {
        .checkbox {
          margin-top: rem-calc(20);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2484";