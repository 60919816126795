.tagline {
  height: rem-calc(100);
  margin-bottom: rem-calc(50);
  background: {
    color: #27272e;
    position: center center;
    repeat: no-repeat;
  }
  background-size: cover;

  .content-container {
    @include xy-grid-container;
    @include xy-grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: $white;
    line-height: 1.3;
    font: {
      weight: 700;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2401";