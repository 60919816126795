@mixin general-bullets {
  @include xy-grid;
  justify-content: center;

  .bullet {
    @extend %with-cursor-without-outline;
    position: relative;
    height: rem-calc(44);
    width: rem-calc(44);
    padding: rem-calc(7);

    &::before {
      @include absolute-center;
      content: '';
      height: rem-calc(10);
      width: rem-calc(10);
      border: 1px solid #d0d2d8;
      border-radius: 50%;
      transition: background 0.25s ease-in-out;
    }

    &:hover,
    &:focus,
    &.slider__bullet--active,
    &.is-active {
      &::before {
        background: #d0d2d8;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2290";