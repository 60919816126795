@mixin reflex-error {
  .reflex-error {
    @include xy-grid;
    display: block;
    margin-bottom: rem-calc(20);
    justify-content: center;

    .message {
      @include xy-cell;
      font-size: x-large;
      text-align: center;
    }

    .button-place {
      margin-top: rem-calc(10);
      @include xy-cell;

      .button {
        @include std-button($alert-red);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2426";