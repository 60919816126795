@mixin pulse  ($speed: 0.9s, $scale: 1.4) {
  @keyframes pulse {
    from {
      transform: scale(1)
    }

    to {
      transform: scale($scale)
    }
  }

  &::before {
    animation: pulse $speed ease-in-out infinite alternate;
  }
}

;@import "sass-embedded-legacy-load-done:2313";