@import 'components/review-in-slider';

.reviews {
  margin-bottom: rem-calc(70);

  .section-title {
    margin-bottom: rem-calc(40);
  }

  .slider-bullets {
    @include general-bullets;
  }

  .review {
    margin-top: 0;
    padding-right: rem-calc(10);
    padding-left: rem-calc(10);

    .inner-container {
      box-shadow: none;
    }

    .image {
      position: relative;
      width: 100%;
      margin-top: 0;
      margin-bottom: rem-calc(15);

      img {
        width: 100%;
      }
    }

    .label {
      @include xy-grid;
      justify-content: flex-end;
      align-content: flex-end;
      top: auto;
      bottom: 0;
      height: rem-calc(105);
      width: rem-calc(170);
      border-bottom-right-radius: rem-calc(12);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom-width: 100px;
        border-bottom-style: solid;
        border-left: 170px solid transparent;
      }

      &.sell::before {
        border-bottom-color: #1788fb;
      }

      &.loan::before {
        border-bottom-color: #d966e2;
      }

      .inner-content,
      .cost {
        width: rem-calc(110);
      }

      .inner-content {
        position: static;
        margin-bottom: rem-calc(5);
        background: transparent !important;
        line-height: 1;
        font: {
          size: rem-calc(12);
        }
        transform: rotate(0deg);
      }

      .cost {
        display: block;
        visibility: visible;
      }
    }

    .title {
      margin-bottom: rem-calc(10);
      font: {
        size: rem-calc(16);
      }
    }

    .cost {
      position: relative;
      margin-bottom: rem-calc(15);
      text-align: center;
      color: $white;
      line-height: 1;
      font: {
        size: rem-calc(16);
      }
    }

    .title-and-description {
      max-width: 100%;
      margin-bottom: rem-calc(15);
    }

    .location-and-cost {
      .cost {
        display: none;
        visibility: hidden;
      }
    }
  }
}

@include breakpoint(large up) {
  .reviews {
    margin-bottom: rem-calc(85);
  }
}

@include breakpoint(1920px up) {
  .reviews {
    .review {
      padding-right: rem-calc(25);
      padding-left: rem-calc(25);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2578";