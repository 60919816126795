.intent-form {
  .intent-type {
    @include xy-grid;
    justify-content: center;
    margin-bottom: rem-calc(20);
    padding-top: rem-calc(10);

    .switch {
      @include switch-container;
      @include switch-paddle-with-fix('switch-paddle');
      @include xy-grid;
      align-items: center;
    }

    .switch-input {
      @include switch-input;
    }

    .switch {
      .switch-paddle {
        background: $switch-background-active;
      }
    }

    .switch-label {
      @include vertical-center;
      display: block;
      width: rem-calc(110);
      color: $medium-gray;
      font: {
        size: rem-calc(14);
        weight: 500;
      }
      white-space: nowrap;

      &.switch-sell {
        left: rem-calc(-120);
        text-align: right;
      }

      &.switch-loan {
        right: rem-calc(-120);
      }
    }

    .switch-input:not(:checked) ~ .switch-paddle {
      .switch-sell {
        color: $medium-green;
      }
    }

    .switch-input:checked ~ .switch-paddle {
      .switch-loan {
        color: $medium-green;
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .intent-type {
      margin-bottom: rem-calc(30);

      .switch-label {
        width: rem-calc(125);
        font: {
          size: rem-calc(18);
        }

        &.switch-sell {
          left: rem-calc(-145);
        }

        &.switch-loan {
          right: rem-calc(-145);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2447";