.intent-form {
  .valuable-specifics {
    &.purse-specifics {
      .buttons-fieldset {
        .input-legend {
          margin-bottom: rem-calc(10);
        }

        .button {
          width: rem-calc(95);

          label {
            padding: rem-calc(20 10 18);
            border: 1px solid #edeef1;
            border-radius: rem-calc(5);
            text-align: center;
            text-transform: uppercase;

            &:hover {
              border-color: $purple;
            }
          }

          input:checked + label {
            border-color: $purple;
          }
        }
      }

      .size-help {
        @include xy-grid-container(rem-calc(940), 0);

        picture {
          @include xy-grid;
          justify-content: center;
        }

        .description {
          @include xy-grid;
          justify-content: center;

          .name {
            font-weight: bolder;
            color: $purple;
          }
        }
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .valuable-specifics {
      &.purse-specifics {
        .buttons-fieldset {
          margin-right: rem-calc(-10);
          margin-left: rem-calc(-10);

          .error-message {
            left: rem-calc(10);
          }

          .input-legend,
          .button {
            margin-right: rem-calc(10);
            margin-left: rem-calc(10);
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2490";