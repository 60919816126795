@import 'stylesheets/form-result';
@import 'mixins/reflex-error';
@import 'mixins/load-spinner';

@import 'stylesheets/additional-info';
@import 'stylesheets/intent-amount';
@import 'stylesheets/intent-type';
@import 'stylesheets/valuables-category';
@import 'stylesheets/valuables-message';
@import 'attachments_component/attachments-component';
@import 'valuable_specifics/stylesheets/valuable-specifics';

.intent-form {
  .attachments {
    @include attachments-component;
  }
  @include xy-grid-container(rem-calc(940), 0);
  @include foundation-forms;
  @include reflex-error;
  position: relative;
  min-height: rem-calc(190);
  @include load-spinner($size: rem-calc(30), $color: $purple);

  .body {
    transition: filter .5s ease-in-out;

    &.working {
      filter: blur(4px);
    }
  }

  .switch-input,
  .checkbox {
    @include switch-checkbox;
  }

  .checkbox {
    .checkbox-input {
      // top: 0;
      // right: 0;
    }
  }

  // &.reflex-not-ready, .reflex-not-ready {
  //   // filter: blur(2px);
  //   label, input, button { pointer-events: none !important; }
  // }

  .load-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
  }

  overflow: hidden;
  .valuable-specifics-place {
    overflow: hidden;
  }

  .appearing {
    transition: max-height .5s ease-in-out;
    overflow: hidden;
    &.blank {
      max-height: 0px;
      overflow: hidden;
    }

    &.present {
      max-height: 1000px;
    }
  }

  .field,
  .fieldset {
    @include error-input;
    position: relative;

    .error-message {
      @include error-message;
    }
  }

  .fieldset {
    > .error-message {
      bottom: rem-calc(15);
      left: rem-calc(15);
    }
  }

  .field {
    @include required-field;

    margin-bottom: rem-calc(15);
    padding-bottom: rem-calc(15);

    .input-label {
      margin-bottom: rem-calc(6);

      .remark {
        color: $medium-gray;
      }
    }

    .input-field {
      height: rem-calc(38);
      margin-bottom: 0;
    }
  }

  .amount-photos-message {
    @include xy-grid;
    align-items: flex-start;

    .field {
      @include error-input;
      @include xy-cell-base;
      width: calc(100% - 30px);
      margin-right: rem-calc(15);
      margin-left: rem-calc(15);
    }
  }

  .button-place {
    position: relative;
    padding: rem-calc(0 15);
    text-align: center;

    .note {
      margin-bottom: rem-calc(16);
      font: {
        size: rem-calc(14);
      }
    }

    .button {
      @include std-button;
      width: rem-calc(270);

      &.working, &.working[disabled] {
        background: $purple;
      }
    }
  }

  .customer-info {
    .description {
      margin-bottom: rem-calc(30);
      padding-right: rem-calc(15);
      padding-left: rem-calc(15);
      text-align: center;
      color: $black;
    }

    .input-fieldset {
      @include xy-grid;
      margin-bottom: rem-calc(10);

      .field {
        @include xy-cell-base;
        width: calc(100% - 30px);
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
      }
    }

    .button-place {
      .button {
        width: rem-calc(165);
        margin-left: rem-calc(10);
      }

      .back-button {
        @include std-button($white, none, $purple);
        margin-right: rem-calc(10);
      }
    }
  }

  // inline error message
  .terms-of-use .error-message {
    position: relative;
    margin-left: rem-calc(10);
  }
}

@include breakpoint(small only) {
  .intent-form {
    .field {
      .input-field {
        font: {
          size: rem-calc(16);
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .amount-photos-message {
      .intent-amount,
      .attachments {
        width: calc(50% - 30px);
      }
    }

    .button-place {
      .button {
        width:  rem-calc(300);
      }
    }

    .customer-info {
      .description {
        margin-bottom: rem-calc(40);
      }

      .input-fieldset {
        .field {
          width: calc(33.333% - 30px);
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .intent-form {
    .amount-photos-message {
      margin-right: rem-calc(-15);
      margin-left: rem-calc(-15);

      .field {
        width: calc(33.333% - 30px);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .fieldset {
      > .error-message {
        bottom: rem-calc(15);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2414";