.intent-form {
  .valuables-category {
    @include xy-grid;
    justify-content: center;
    margin-bottom: rem-calc(35);

    .button {
      @include radio-with-icon;
      margin: rem-calc(5);

      label {
        height: rem-calc(85);
        width: rem-calc(85);
        padding: rem-calc(55 10 10);
        border: 1px solid #edeef1;
        border-radius: rem-calc(8);
        text-align: center;
        font: {
          size: rem-calc(14);
        }
        text-transform: uppercase;
        // transition: color 0.25s ease-in-out;

        .icon {
          @include horizontal-center;
          top: rem-calc(10);
          height: rem-calc(32);
          width: rem-calc(32);
        }

        &:hover {
          box-shadow: 0 0 16px rgba(229, 229, 229, 0.8);
        }
      }

      [type="radio"]:checked + label {
        background: $purple;
        box-shadow: none;
        color: $white;
      }
    }
  }
}

@include breakpoint(large up) {
  .intent-form {
    .valuables-category {
      margin-right: rem-calc(-10);
      margin-left: rem-calc(-10);

      .button {
        margin: rem-calc(10);

        label {
          height: rem-calc(125);
          width: rem-calc(125);
          padding-top: rem-calc(85);

          font: {
            size: rem-calc(16);
          }

          .icon {
            top: rem-calc(30);
            height: rem-calc(45);
            width: rem-calc(45);
          }
        }
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .valuables-category {
      .button {
        label {
          height: rem-calc(140);
          width: rem-calc(140);
          padding-top: rem-calc(95);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2454";