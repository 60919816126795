.promotion-counter {
  margin-bottom: rem-calc(70);
  padding: rem-calc(35 0);
  background: #eef5ff;

  .content-container {
    @include xy-grid-container;
  }

  .image-container {
    display: block;
    margin-bottom: rem-calc(20);
    text-align: center;

    .image {
      width: 100%;
      max-width: rem-calc(770);
    }
  }
}

@include breakpoint(large up) {
  .promotion-counter {
    margin-bottom: rem-calc(85);
    padding-bottom: rem-calc(60);
  }
}

;@import "sass-embedded-legacy-load-done:2577";