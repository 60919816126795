.intent-form {
  .valuable-specifics {
    &.diamonds-specifics {
      .color-fieldset {
        @include xy-grid;

        > .field,
        .other-color-section  {
          @include xy-cell-base;
          width: calc(100% - 30px);
          margin-right: rem-calc(15);
          margin-left: rem-calc(15);
        }
      }

      .checkbox-fieldset {
        .field {
          @include xy-cell-base;
          width: calc(100% - 30px);
          margin-right: rem-calc(15);
          margin-left: rem-calc(15);
        }
      }

      .other-color-section {
        @include xy-grid;
        align-items: flex-end;
        margin-bottom: rem-calc(15);

        .button {
          @extend %with-cursor-without-outline;
          height: rem-calc(38);
          margin-bottom: rem-calc(15);
          padding: rem-calc(10 0 9);
          color: $purple;
          font: {
            size: rem-calc(11);
          }
          text-transform: uppercase;
        }

        .field {
          @include xy-grid;
          align-items: center;
          width: auto;
          margin: 0;

          .input-label {
            @include xy-cell-base;
            margin: rem-calc(0 10 0 0);
          }

          .input-field {
            @include xy-cell-base(auto);
          }
        }
      }

      .color {
        &:not(.ready) {
          opacity: 0;
        }

        opacity: 1;

        .color-slider {
          @include xy-grid;
          position: relative;
          height: rem-calc(50);
          width: calc(100% - 26px);
          margin: 0 rem-calc(13);

          &::before {
            content: '';
            @include slider-container;
            position: absolute;
            top: rem-calc(28);
            height: rem-calc(12);
            width: 100%;
            margin: 0;
            background-color: #eef5ff;
            border-radius: rem-calc(4);
          }

          .color-value {
            @include flex-grid-column;
            position: relative;
            height: rem-calc(24);
            margin-bottom: rem-calc(4);
            text-align: center;
            line-height: rem-calc(24);
            font: {
              size: rem-calc(11);
            }
            text-transform: uppercase;
            cursor: pointer;

            &::before {
              content: '';
              position: absolute;
              bottom: rem-calc(-16);
              right: rem-calc(-1);
              z-index: 1;
              height: rem-calc(12);
              width: rem-calc(1);
              background: $white;
            }

            &.selected {
              color: $black;
              font-weight: bolder;
            }
          }

          .slider-fill {
            @include slider-fill;
            height: rem-calc(12);
            margin-top: rem-calc(28);
            background: linear-gradient(to right, rgba(13,76,230,1) 0%,rgba(3,29,135,1) 100%);
            border-radius: rem-calc(4);
            transition: none;
          }

          .slider-handle {
            @include slider-handle;
            margin-top: rem-calc(9);
            box-shadow: 0 0 4px rgba($black, 0.25);
            outline: none;
            transition-property: transform;
            cursor: grab;

            &:hover {
              background: $white;
            }

            &.is-dragging {
              transition-property: left;
              transform: scale(1.2, 1.2) translateY(-40%);
              cursor: grabbing;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .valuable-specifics {
      &.diamonds-specifics {
        .color-fieldset {
          > .field {
            width: calc(66.666% - 30px);
          }

          .other-color-section {
            width: auto;
          }
        }

        .checkbox-fieldset {
          align-items: center;

          .field {
            &:nth-child(2),
            &:nth-child(3) {
              width: calc(50% - 30px);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .intent-form {
    .valuable-specifics {
      &.diamonds-specifics {
        .color-fieldset {
          margin-right: rem-calc(-15);
          margin-left: rem-calc(-15);
        }

        .checkbox-fieldset {
          .field {
            width: calc(33.333% - 30px);

            &:nth-child(2),
            &:nth-child(3) {
              width: calc(33.333% - 30px);
            }
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2473";