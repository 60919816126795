.intent-form {
  .valuable-specifics {
    &.gold-specifics {
      .buttons-fieldset {
        .button {
          label {
            padding-left: rem-calc(50);
          }
        }
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .valuable-specifics {
      &.gold-specifics {
        .buttons-fieldset {
          .button {
            width: auto;
            margin-right: rem-calc(25);
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2477";