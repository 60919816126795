/// Adds styles for the background and paddle of a switch. Apply this to a `<label>` within a switch.
@mixin switch-paddle-with-fix($name) {
  $switch-width: $switch-height * 2;
  $paddle-height: $switch-height - ($switch-paddle-offset * 2);
  $paddle-width: $switch-height - ($switch-paddle-offset * 2);
  $paddle-active-offest: $switch-width - $paddle-width - $switch-paddle-offset;

  .#{$name} {
    position: relative;
    display: block;
    width: $switch-width;
    height: $switch-height;
    margin: 0;
    border-radius: $switch-radius;
    background: $switch-background;
    transition: $switch-paddle-transition;

    // Resetting these <label> presets so type styles cascade down
    font-weight: inherit;
    color: inherit;
    cursor: pointer;

    &:focus-visible {
      box-shadow: 0 0 10px rgba(229, 229, 229, 0.8);
      outline: 0;
    }

    // The paddle itself
    &::after {
      position: absolute;
      top: $switch-paddle-offset;
      #{$global-left}: $switch-paddle-offset;

      display: block;
      width: $paddle-width;
      height: $paddle-height;

      transform: translate3d(0, 0, 0);
      border-radius: $switch-paddle-radius;
      background: $switch-paddle-background;
      transition: $switch-paddle-transition;
      content: '';
    }
  }

  // Change the visual style when the switch is active
  input:checked ~ .#{$name} {
    background: $switch-background-active;

    &::after {
      #{$global-left}: $paddle-active-offest;
    }
  }

  // indicate a disabled switch
  input:disabled ~ .#{$name} {
    cursor: $switch-cursor-disabled;
    opacity: $switch-opacity-disabled;
  }

  input:focus ~ .#{$name} {
    @include disable-mouse-outline;
  }
}

@mixin switch-checkbox {
  @include switch-container;
  @include switch-paddle-with-fix('checkbox-label');
  @include xy-grid;
  align-items: center;

  .checkbox-input {
    @include switch-input;
  }

  .checkbox-label {
    margin-right: 0;
    margin-left: rem-calc(15);
  }

  .checkbox-legend {
    margin: 0;
    color: $black;
    line-height: 1;
    font: {
      size: rem-calc(14);
      weight: 400;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2203";