.get-estimate {
  margin-bottom: rem-calc(100);

  .estimator {
    .estimator-title {
      margin-bottom: rem-calc(15);
      text-align: center;
      color: $black;
      font: {
        size: rem-calc(22);
        weight: 500;
      }
      text-transform: uppercase;
    }

    .estimator-text {
      @include xy-grid-container(rem-calc(768));
      margin-bottom: rem-calc(35);
      text-align: center;
    }
  }
}

@include breakpoint(medium up) {
  .get-estimate {
    .estimator {
      .estimator-title {
        margin-bottom: rem-calc(25);
        font: {
          size: rem-calc(32);
        }
      }

      .estimator-text {
        margin-bottom: rem-calc(50);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2539";