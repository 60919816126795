.promotion-banner {
  overflow: hidden;

  .content-container {
    @include xy-gutters($grid-margin-gutters, padding);
    @include xy-grid;
  }

  .image-container,
  .description {
    @include xy-cell-base;
    width: 100%;
  }

  .image-container {
    @include xy-grid;
    justify-content: center;
    align-items: center;

    .image {
      width: 100%;
      max-width: rem-calc(520);
    }

    source.image {
      width: auto;
    }
  }

  .description {
    padding-top: rem-calc(15);
    text-align: center;
  }

  .section-label {
    margin-bottom: rem-calc(5);
  }

  .section-title {
    margin-bottom: rem-calc(15);
    line-height: 1.25;
  }

  .text {
    margin-bottom: rem-calc(20);
    font: {
      size: rem-calc(16);
    }
  }

  .color-text {
    color: $black;
  }

  .button {
    @include std-button;
    width: rem-calc(200);
    margin-bottom: 0;
  }
}

@include breakpoint(small only) {
  .promotion-banner {
    .section-label {
      font: {
        size: rem-calc(12);
      }
    }

    .section-title {
      font: {
        size: rem-calc(28);
      }
    }

    .button {
      min-width: rem-calc(155);
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
      font: {
        size: rem-calc(12);
      }
    }
  }
}

@include breakpoint(medium down) {
  .promotion-banner {
    .description {
      margin-bottom: rem-calc(35);
    }
  }
}

@include breakpoint(medium up) {
  .promotion-banner {
    .section-title {
      font: {
        size: rem-calc(32);
      }
    }

    .text {
      margin-bottom: rem-calc(25);
      font: {
        size: rem-calc(16);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .promotion-banner {

    .section-title {
      font: {
        size: rem-calc(50);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2387";