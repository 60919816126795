@mixin general-arrows {
  .arrow {
    @extend %with-cursor-without-outline;
    position: relative;
    height: rem-calc(71);
    width: rem-calc(71);
    background: $white;
    box-shadow: 0 0 16px rgba(229, 229, 229, 0.8);
    border-radius: rem-calc(8);

    .icon {
      @include absolute-center;
      height: rem-calc(8);
      width: rem-calc(29);
      fill: $green;
    }
  }

  @include breakpoint(small only) {
    .arrow {
      height: rem-calc(48);
      width: rem-calc(48);

      .icon {
        // height: rem-calc(4);
        // width: rem-calc(15);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2274";