html.video-modal-open {
  position: fixed;
  width: 100%;
  overflow-y: hidden;

  &.zf-has-scroll {
    overflow-y: scroll;
  }

  body { // sass-lint:disable-line no-qualifying-elements
    overflow-y: hidden;
  }

  .video-modal-overlay {
    display: block;
  }

  .video-modal {
    display: block;
  }
}

// Overlay
.video-modal-overlay {
  @include reveal-overlay;
}

.video-modal {
  @include reveal-modal-base;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  overflow-y: visible;

  top: 0;
  padding: 0;
  line-height: 0;
  width: 100%;
  height: auto;
  background: none;

  video {
    width: 100%;
  }

  &:focus-within,
  &:hover {
    .close-button {
      background: #0008;
    }
  }

  .close-button {
    @include universal-close-button($white, rem-calc(30), rem-calc(35));
    position: absolute;
    right: rem-calc(10);
    top: rem-calc(10);
    border-radius: 50%;

    &:hover,
    &:focus {
      background: #000A;
    }
  }
}


;@import "sass-embedded-legacy-load-done:2507";