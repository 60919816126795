.about-us {
  position: relative;
  margin-top: rem-calc(-50);
  margin-bottom: rem-calc(70);
  background: #eef5ff;

  .content-container {
    @include xy-grid-container;
    @include xy-grid;
    padding-top: rem-calc(75);
    padding-bottom: rem-calc(30);

    .section-title {
      margin-bottom: rem-calc(25);
    }

    .section-subtitle {
      font: {
        size: rem-calc(16);
      }
    }

    .image-container,
    .description {
      @include xy-cell-base;
      width: 100%;
    }

    .image-container {
      @include xy-grid;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: rem-calc(20);

      .image {
        width: 100%;
        max-width: rem-calc(520);
      }
    }

    .text,
    .bullet-list {
      margin-bottom: rem-calc(25);
    }

    .button-place {
      text-align: center;
    }

    .button {
      @include std-button;
      width: rem-calc(200);
    }
  }
}

@include breakpoint(large up) {
  .about-us {
    margin-top: rem-calc(-170);

    .content-container {
      padding-top: rem-calc(225);
      padding-left: 0;

      .image-container,
      .description {
        width: 50%;
      }

      .image-container {
        align-items: center;
        padding-right: rem-calc(30);

        .image {
          max-width: 100%;
        }
      }

      .section-label,
      .section-title {
        text-align: left;
      }

      .section-subtitle {
        font: {
          size: rem-calc(18);
        }
      }

      .bullet-list {
        margin-bottom: rem-calc(30);
      }

      .button-place {
        text-align: left;
      }
    }
  }
}

@include breakpoint(large up) {
  .about-us {
    .image-container {
      padding-right: rem-calc(45);
    }

    .description {
      padding-left: rem-calc(45);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2518";