.intent-form {
  .valuable-specifics {
    &.watches-specifics {
      .material {
        .error-message {
          bottom: rem-calc(5);
        }

        .button {
          margin-bottom: rem-calc(20);

          &.stainless-steel {
            width: auto;
          }

          label {
            padding-top: rem-calc(21);
            padding-bottom: rem-calc(21);
            padding-left: rem-calc(65);

            .icon {
              height: rem-calc(56);
              width: rem-calc(56);
              border: 1px solid #edeef1;
              border-radius: rem-calc(5);
            }

            &:hover {
              .icon {
                border-color: $purple;
              }
            }
          }

          input:checked + label {
            .icon {
              border-color: $purple;
            }
          }
        }


        .button.other {
          label {
            padding-left: rem-calc(10);
            color: $purple;
            font: {
              size: rem-calc(11);
            }
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@include breakpoint(455px up) {
  .intent-form {
    .valuable-specifics {
      &.watches-specifics {
        .material {
          .button {
            width: rem-calc(150);
          }
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .valuable-specifics {
      &.watches-specifics {
        .material {
          .button {
            width: auto;
            margin-right: rem-calc(20);
          }

          .button.other,
          .field.other {
            width: rem-calc(280);
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2494";