.form-result {
  text-align: center;
  color: $black;

  .title {
    margin-bottom: rem-calc(10);
    font: {
      size: rem-calc(16);
      weight: 700;
    }
  }

  .text {
    margin-bottom: rem-calc(20)
  }

  .button {
    @include std-button;
    width: rem-calc(210);
  }
}

@include breakpoint(medium up) {
  .form-result {
    .title {
      font: {
        size: rem-calc(18);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2419";