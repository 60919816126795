@mixin attachments-component () {
  .input-label {
    color: $black;
    line-height: 1;
    font: {
      size: rem-calc(14);
    }
  }

  .drop-zone {
    @include xy-grid;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-height: rem-calc(80);
    padding: rem-calc(10);
    background: #f9fbff;
    border: 1px dashed #878c98;
    border-radius: rem-calc(2);
    cursor: pointer;

    &.drag-nearby {
      border: {
        width: 3px;
        style: solid;
        color: rgba($medium-green, 0.25);
      }
    }

    &.drag-over {
      border: {
        width: 3px;
        style: solid;
        color: $medium-green;
      }

      .help {
        display: none;
      }
    }
  }

  .help {
    margin: auto 0;
    text-align: center;
    color: #878c98;
    font: {
      size: rem-calc(14);
    }

    .highlight-text {
      display: block;
      position: relative;
      // padding-left: rem-calc(22);
      color: $medium-green;

      .icon {
        // @include vertical-center;
        // left: 0;
        display: inline-block;
        height: rem-calc(14);
        width: rem-calc(13);
        margin: rem-calc(0 9 -2 0);
        fill: $medium-green;
      }
    }
  }

  .attachment {
    @include xy-cell-base;
    position: relative;
    height: rem-calc(79);
    width: rem-calc(79);
    margin: rem-calc(5);
    background: $light-gray;
    border-radius: rem-calc(5);

    .remove {
      @extend %with-cursor-without-outline;
      position: absolute;
      top: rem-calc(1);
      right: rem-calc(3);
      z-index: 2;
      height: rem-calc(10);
      width: rem-calc(10);

      .icon {
        height: rem-calc(10);
        width: rem-calc(10);
        fill: $white;
      }
    }

    .preview {
      position: relative;
      min-width: 100%;
      min-height: 100%;
      max-width:  100px;
      border-radius: rem-calc(5);
      background-size: cover;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba($black, 0.4);
        border-radius: inherit;
      }

      img {
        border-radius: inherit;
      }
    }

    .name,
    .error,
    .progress-bar {
      @include horizontal-center;
    }

    .name {
      overflow: hidden;
      bottom: rem-calc(5);
      width: 85%;
      font-size: 0.8em;
      white-space: nowrap;
      color: $white;
    }

    .error {
      top: rem-calc(20);
      width: 85%;
      color: $alert-color;
      text-align: center;
      font: {
        size: rem-calc(10);
        weight: 500;
      }
      white-space: wrap;
    }

    .progress-bar {
      bottom: rem-calc(25);
      background-color: $dark-gray;
      width: 85%;
      height: rem-calc(12);
      border-radius: rem-calc(25);
    }

    &.failed {
      .progress-bar { display: none; }
    }

    .progress-line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: $medium-green;
      border-radius: inherit;
    }

    .progress-text {
      @include absolute-center;
      margin-top: rem-calc(-1);
      color: $white;
      line-height: 1;
      font: {
        size: rem-calc(10);
        weight: 500;
      }
    }

    &.completed {
      &::before {
        @include absolute-center;
        z-index: 1;
        content: '';
        height: rem-calc(35);
        width: rem-calc(35);
        background-color: rgba($white, 0.8);
        mask: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDk2IDk2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGc+PHBhdGggZD0iTTU4LjM5NDUsMzIuMTU2Myw0Mi45OTYxLDUwLjYyNWwtNS4zOTA2LTYuNDYyOWE1Ljk5NSw1Ljk5NSwwLDEsMC05LjIxMSw3LjY3NThsOS45OTYxLDEyYTUuOTkxNCw1Ljk5MTQsMCwwLDAsOS4yMTEuMDA1OWwyMC4wMDM5LTI0YTUuOTk4OCw1Ljk5ODgsMCwxLDAtOS4yMTEtNy42ODc1WiIvPjxwYXRoIGQ9Ik00OCwwQTQ4LDQ4LDAsMSwwLDk2LDQ4LDQ4LjA1MTIsNDguMDUxMiwwLDAsMCw0OCwwWm0wLDg0QTM2LDM2LDAsMSwxLDg0LDQ4LDM2LjAzOTMsMzYuMDM5MywwLDAsMSw0OCw4NFoiLz48L2c+PC9zdmc+') no-repeat center / cover;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2465";