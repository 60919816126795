.benefits {
  padding-bottom: rem-calc(5);

  .item {
    &:nth-child(1) {
      .icon {
        margin-top: rem-calc(15);
        margin-bottom: rem-calc(30);
      }
    }
  }
}

@include breakpoint(medium down) {
  .benefits {
    margin-right: rem-calc(10);
    margin-left: rem-calc(10);
  }
}

@include breakpoint(medium up) {
  .benefits {
    .item {
      width: rem-calc(390);
      margin-right: rem-calc(20);
      margin-left: rem-calc(20);
    }
  }
}

@include breakpoint(large only) {
  .benefits {
    width: calc(100% - 50px);
  }
}

@include breakpoint(xlarge up) {
  .benefits {
    .item {
      margin-right: rem-calc(15);
      margin-left: rem-calc(15);

      &:nth-child(2),
      &:nth-child(3) {
        width: rem-calc(330);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2530";