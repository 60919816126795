@mixin bullet-list {
  margin-left: 0;
  list-style: none;

  li {
    position: relative;
    min-height: rem-calc(19);
    margin-bottom: rem-calc(10);
    padding-left: rem-calc(35);

    &::before {
      content: '';
      position: absolute;
      top: rem-calc(3);
      left: 0;
      height: rem-calc(20);
      width: rem-calc(20);
      background-color: $black;
      mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTM5MzkgMEM0LjUyNzY5IDAgMC4xMjkzMzMgNC4zOTgzNSAwLjEyOTMzMyA5LjgxMDA1QzAuMTI5MzMzIDE1LjIyMTggNC41Mjc2OSAxOS42MjAxIDkuOTM5MzkgMTkuNjIwMUMxNS4zNTExIDE5LjYyMDEgMTkuNzQ5NCAxNS4yMjE4IDE5Ljc0OTQgOS44MTAwNUMxOS43NDk0IDQuMzk4MzUgMTUuMzQ2OCAwIDkuOTM5MzkgMFpNOS45MzkzOSAxOS4wODU0QzQuODI1MjIgMTkuMDg1NCAwLjY1OTcyMyAxNC45MjQyIDAuNjU5NzIzIDkuODA1NzRDMC42NTk3MjMgNC42ODcyNyA0LjgyMDkxIDAuNTMwMzkgOS45MzkzOSAwLjUzMDM5QzE1LjA1NzkgMC41MzAzOSAxOS4yMTkxIDQuNjkxNTggMTkuMjE5MSA5LjgxMDA1QzE5LjIxOTEgMTQuOTI4NSAxNS4wNTM2IDE5LjA4NTQgOS45MzkzOSAxOS4wODU0WiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTEyLjkyMzMgNy40NTEyOUMxMi44NTQzIDcuNDUxMjkgMTIuNzg1MyA3LjQ3NzE3IDEyLjczNzkgNy41Mjg5MUw4LjcxOTAxIDExLjUyNjJMNy4xNDUwOSA5Ljk0MzY5QzcuMDkzMzUgOS44OTE5NSA3LjAyNDM1IDkuODY2MDcgNi45NTUzNiA5Ljg2NjA3QzYuODg2MzcgOS44NjYwNyA2LjgyMTY5IDkuODkxOTUgNi43Njk5NCA5Ljk0MzY5QzYuNzIyNTEgOS45OTExMyA2LjY5MjMyIDEwLjA1NTggNi42OTIzMiAxMC4xMjkxQzYuNjkyMzIgMTAuMjAyNCA2LjcxODE5IDEwLjI2NzEgNi43Njk5NCAxMC4zMTg4TDguNTI0OTcgMTIuMDg2OEM4LjU3NjcxIDEyLjEzODYgOC42NDE0IDEyLjE2NDQgOC43MTQ3IDEyLjE2NDRDOC43ODM3IDEyLjE2NDQgOC44NDgzOCAxMi4xMzg2IDguOTAwMTIgMTIuMDg2OEwxMy4xMDg3IDcuOTA0MDdDMTMuMjEyMiA3LjgwMDU3IDEzLjIxMjIgNy42MzI0IDEzLjEwODcgNy41Mjg5MUMxMy4wNTcgNy40ODE0OCAxMi45OTIzIDcuNDUxMjkgMTIuOTIzMyA3LjQ1MTI5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') no-repeat center / cover;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2145";