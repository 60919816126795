
.estimator {
  @include xy-grid-container(rem-calc(1130));
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(25);
  background: #dcf8ff;

  .estimator-container {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(25);
    background: $white;
    border-radius: rem-calc(20);
  }
}

@include breakpoint(small only) {
  .estimator {
    padding-right: rem-calc(10);
    padding-left: rem-calc(10);
  }
}

@include breakpoint(medium up) {
  .estimator {
    padding-top: rem-calc(55);

    .estimator-container {
      padding: rem-calc(35 20 45);
    }
  }
}

@include breakpoint(large up) {
  .estimator {
    .estimator-container {
      padding-right: rem-calc(50);
      padding-left: rem-calc(50);
    }
  }
}


@include breakpoint(xlarge up) {
  .estimator {
    border-radius: rem-calc(20);
  }
}

;@import "sass-embedded-legacy-load-done:2376";