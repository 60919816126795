@mixin spinner($name: 'spinner') {
  @keyframes #{$name}-spin {
    to { transform: rotate(360deg); }
  }

  .#{$name} {
    animation: #{$name}-spin 1.2s infinite linear;
  }
}

@include spinner($name: 'spinner');

@mixin load-spinner($name: 'load-spinner', $color: '#333', $size: rem-calc(12)) {
  @include spinner($name: $name);
  $line-size: $size * 0.16;

  .#{$name} {
    width: $size * 1.25;
    height: $size * 1.25;
    border-radius: 50%;
    background: transparent;
    border-top: $line-size * 1.25 solid #{$color};
    border-bottom: $line-size * 1.25 solid #{$color};
    border-right: $line-size * 1.25 solid;
    border-left: $line-size * 1.25 solid;
    border-right-color: transparent;
    border-left-color: transparent;

    @include breakpoint(small only) {
      height: $size;
      width: $size;
      border-top: $line-size solid #{$color};
      border-bottom: $line-size solid #{$color};
      border-right: $line-size solid;
      border-left: $line-size solid;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }

  span.#{$name},
  span.border-#{$name} {
    display: inline-block;
  }
}

;@import "sass-embedded-legacy-load-done:2434";