.partners {
  margin-bottom: rem-calc(40);

  .content-container {
    @include xy-grid-container;
  }

  .section-title {
    margin-bottom: rem-calc(15);
  }

  .list {
    @include xy-grid;
    justify-content: center;
    align-items: center;
    margin: rem-calc(0 -15);
    padding: 0;
    list-style: none;

    li {
      margin: rem-calc(10 10);

      .partner-logo {
        width: rem-calc(150);
      }

      &:nth-child(5) {
        .partner-logo {
          width: rem-calc(118);
        }
      }

      &:nth-child(6) {
        .partner-logo {
          width: rem-calc(192);
        }
      }

      &:nth-child(7) {
        .partner-logo {
          width: rem-calc(105);
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .partners {
    .content-container {
      padding-right: rem-calc(25);
      padding-left: rem-calc(25);
    }

    .list {
      margin-right: rem-calc(-25);
      margin-left: rem-calc(-25);

      li {
        margin: rem-calc(25);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .partners {
    .section-title {
      margin-bottom: rem-calc(20);
    }

    .list {
      margin-right: rem-calc(-35);
      margin-left: rem-calc(-35);

      li {
        margin-right: rem-calc(35);
        margin-left: rem-calc(35);

        .partner-logo {
          width: rem-calc(180);
        }

        &:nth-child(3) {
          .partner-logo {
            width: rem-calc(168);
          }
        }

        &:nth-child(5) {
          .partner-logo {
            width: rem-calc(138);
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2557";