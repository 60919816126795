.tagline {
  &.lazy-loaded {
    background: {
      image: url('~pages/home/tagline/bg.png');
    }
  }

  .content-container {
    font: {
      size: rem-calc(22);
    }
  }
}

@include breakpoint(medium up) {
  .tagline {
    .content-container {
      font: {
        size: rem-calc(36);
      }
    }
  }
}

@include breakpoint(large up) {
  .tagline {
    .content-container {
      font: {
        size: rem-calc(46);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2582";