.review {
  margin-top: rem-calc(30);
  padding: rem-calc(15);
  text-align: center;
  cursor: grab;

  @at-root .reviews-list.slider--dragging .review {
    cursor: grabbing;
  }

  .inner-container {
    @include xy-grid;
    justify-content: center;
    align-content: space-between;
    position: relative;
    min-height: rem-calc(370);
    width: 100%;
    padding: rem-calc(0 15 30);
    background: $white;
    border-radius: rem-calc(8);
    box-shadow: 0 0 16px rgba(229, 229, 229, 0.8);
  }

  .image {
    margin-top: rem-calc(-30);
    margin-bottom: rem-calc(17);
    margin-right: auto;
    margin-left: auto;

    img {
      width: rem-calc(204);
    }
  }

  .label {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    width: 67px;
    height: 81px;
    text-align: right;

    .inner-content {
      position: absolute;
      top: 15px;
      right: -36px;
      width: rem-calc(120);
      transform: rotate(49deg);
      text-align: center;
      color: $white;
      line-height: rem-calc(28);
      font: {
        size: rem-calc(16);
        weight: 700;
      }
      text-transform: uppercase;
    }

    &.sell .inner-content {
      background: #1788fb;
    }

    &.loan .inner-content {
      background: #d966e2;
    }

    .cost {
      display: block;
      visibility: hidden;
    }
  }

  .title-and-description,
  .location-and-costt {
    @include xy-cell-base;
  }

  .title-and-description {
    width: 100%;
    max-width: rem-calc(375);
    padding: rem-calc(0 10);
  }

  .title {
    margin-bottom: rem-calc(15);
    color: $black;
    line-height: 1.3;
    font: {
      size: rem-calc(20);
      weight: 500;
    }
    text-transform: uppercase;
  }

  .location-and-cost {
    align-self: flex-end;
    width: 100%;
  }

  .location {
    margin-bottom: rem-calc(25);
    color: $black;
    font: {
      size: rem-calc(16);
      weight: 500;
    }
  }

  .cost {
    color: $purple;
    line-height: 1;
    font: {
      size: rem-calc(22);
      weight: 700;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2579";