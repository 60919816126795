@mixin error-input {
  &.error-input {
    .input-field {
      border-color: $alert-color;
    }

    .input-label,
    .input-legend {
      color: $alert-color;
    }
  }
}

@mixin error-message {
  position: absolute;
  bottom: 0;
  left: 0;
  color: $alert-color;
  line-height: 1.1;
  font: {
    size: rem-calc(12);
  }
}

;@import "sass-embedded-legacy-load-done:2220";