@import './diamonds';
@import './gold';
@import './jewelry';
@import './other';
@import './purse';
@import './watches';

.intent-form {
  .valuable-specifics {
    .attention-message-place {
      margin: rem-calc(0 15 35);
    }

    .attention-message {
      @include xy-grid;
      align-items: center;
      color: $black;
      font: {
        size: rem-calc(14);
      }
      text-transform: capitalize;

      .icon {
        height: rem-calc(20);
        width: rem-calc(20);
        margin-right: rem-calc(10);
        fill: $alert-color;
      }
    }

    .input-fieldset,
    .checkbox-fieldset {
      @include xy-grid;
    }

    .buttons-fieldset {
      @include xy-grid;

      .input-legend,
      .button,
      .field {
        @include xy-cell-base;
      }

      .input-legend {
        width: calc(100% - 30px);
        margin: rem-calc(0 15 15);
        color: $black;
        line-height: 1;
        font: {
          size: rem-calc(14);
          weight: 400;
        }

        &.error-input {
          color: $alert-color;
        }
      }

      .error-message {
        color: $alert-color;
      }

      .button,
      .field {
        width: rem-calc(115);
        margin: rem-calc(0 15 30);
      }

      .button {
        @include radio-with-icon;

        label {
          padding: rem-calc(14 10 14 45);
          background: transparent;
          white-space: nowrap;

          .icon {
            @include vertical-center;
            left: 0;
            height: rem-calc(42);
            width: rem-calc(42);
          }
        }

        input:checked ~ label,
        &.is-active label {
          color: $purple;
        }
      }

      .field {
        .input-label {
          font: {
            size: rem-calc(12);
          }
        }
      }
    }

    .input-fieldset {
      margin-bottom: rem-calc(10);

      .field {
        @include xy-cell-base;
        width: calc(100% - 30px);
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
      }
    }

    .checkbox-fieldset {
      margin-bottom: rem-calc(10);

      .field {
        margin: rem-calc(0 15 15);
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .valuable-specifics {
      .buttons-fieldset {
        margin-bottom: rem-calc(10);

        .field {
          @include xy-grid;
          align-items: center;
          margin-right: 0;

          .input-label {
            margin-bottom: 0;
            margin-right: rem-calc(10);
            font: {
              size: rem-calc(14);
            }
          }

          .input-field {
            width: rem-calc(135);
          }
        }
      }

      .input-fieldset {
        .field {
          width: calc(33.333% - 30px);
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .intent-form {
    .valuable-specifics {
      .buttons-fieldset,
      .input-fieldset,
      .checkbox-fieldset {
        margin-right: rem-calc(-15);
        margin-left: rem-calc(-15);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .valuable-specifics {
      .attention-message-place {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2469";