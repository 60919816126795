.intent-form {
  .valuables-message {
    .textarea-field {
      height: rem-calc(80);
      margin-bottom: 0;
    }
  }
}

@include breakpoint(small only) {
  .intent-form {
    .valuables-message {
      .textarea-field {
        font: {
          size: rem-calc(16);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2462";