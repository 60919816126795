.how-it-work {
  margin-bottom: rem-calc(70);
  overflow: hidden;

  .section-header {
    @include xy-grid-container(rem-calc(1235));
  }

  .content-container {
    @include xy-gutters($grid-margin-gutters, padding);
  }

  .section-title {
    margin-bottom: rem-calc(20);
  }

  .tabs-title {
    @include tabs-title;
    font: {
      size: rem-calc(13);
    }
    text-transform: uppercase;

    a {
      position: relative;
      padding: rem-calc(12 0 10);
      color: $medium-gray;
      outline: none;
      transition: background-color 0.25s ease-in-out;

      &::before {
        content: '';
        position: absolute;
      }
    }

    > a:hover {
      color: $black;
    }

    > a:focus,
    > a[aria-selected='true'],
    a.is-active {
      color: $black;
      background: none;

      &::before {
        background: $green;
      }
    }

    a.blink {
      &::before {
        @keyframes blink {
          from { background-color: none; }
          to   { background-color: $green; }
        }
        animation: 0.5s steps(2) 0s infinite alternate blink;
      }
    }
  }

  .tabs-panel {
    @include tabs-panel;
  }

  .inner-container {
    @include xy-grid;
  }

  .text,
  .images-place {
    @include xy-cell-base;
    width: 100%;
  }

  .images-place {
    @include xy-grid;
    align-items: center;
    position: relative;
  }

  .slider-arrows {
    @include general-arrows;
    @include absolute-center;
    @include xy-grid;
    z-index: 3;
    justify-content: space-between;
    width: 100%;
    // padding: rem-calc(0 16);
  }

  .step1-images,
  .step2-images,
  .step3-images {
    position: relative;
    width: 100%;

    .image {
      position: absolute;
      bottom: 0;

      img {
        width: 100%;
      }
    }
  }

  .step1-images {
    .woman {
      left: rem-calc(-30);
      width: rem-calc(111);
      z-index: 1;
    }

    .man {
      right: rem-calc(-30);
      bottom: rem-calc(28);
      width: rem-calc(396);
    }

    .button {
      right: rem-calc(-90);
      bottom: rem-calc(280);
    }
  }

  .step2-images {
    .woman {
      right: rem-calc(-30);
      width: rem-calc(172);
      z-index: 1;
    }

    .man {
      right: 0;
      left: rem-calc(-30);
      width: rem-calc(332);
    }
  }

  .step3-images {
    .man {
      right: rem-calc(-30);
      width: rem-calc(229);
      z-index: 1;
    }

    .woman {
      left: rem-calc(-30);
      width: rem-calc(170);
      bottom: rem-calc(115);
    }

    .money {
      left: rem-calc(-30);
      width: rem-calc(332);
      bottom: rem-calc(115);
      z-index: 2;
    }
  }

  .title {
    margin-bottom: rem-calc(16);
    color: $black;
    font: {
      size: rem-calc(16);
      weight: 500;
    }
  }
}

@include breakpoint(small only) {
  .how-it-work {
    .images-place {
      height: rem-calc(230);

      &.step1 {
        padding-top: rem-calc(25);
      }
    }

    .slider-arrows {
      width: calc(100% + 30px);
    }

    .step1-images {
      // width: rem-calc(521);
      height: rem-calc(155);
      width: 100%;
      max-width: rem-calc(375);
      margin: 0 auto;

      .woman {
        width: rem-calc(50);
        z-index: 1;
      }

      .man {
        bottom: rem-calc(5);
        width: rem-calc(185);
      }

      .button {
        bottom: rem-calc(100);
        width: rem-calc(60);
      }
    }

    .step2-images {
      height: rem-calc(206);
      width: 100%;
      max-width: rem-calc(350);
      margin: 0 auto;
      // width: rem-calc(478);

      .woman {
        width: rem-calc(80);
        z-index: 1;
      }

      .man {
        width: rem-calc(150);
      }
    }

    .step3-images {
      height: rem-calc(228);
      width: 100%;
      max-width: rem-calc(375);
      margin: 0 auto;

      .man {
        width: rem-calc(108);
        z-index: 1;
      }

      .woman {
        width: rem-calc(90);
        bottom: rem-calc(40);
      }

      .money {
        width: rem-calc(220);
        bottom: rem-calc(20);
        bottom: 0;
      }
    }
  }
}

@include breakpoint(medium down) {
  .how-it-work {
    .tabs {
      @include tabs-container;
      @include xy-grid;
      justify-content: center;
      border: 0;
      border-bottom: 1px solid #e9ebf8;
    }

    .tabs-title {
      a {
        padding-right: rem-calc(20);
        padding-left: rem-calc(20);

        &::before {
          bottom: rem-calc(-1);
          left: 0;
          height: rem-calc(1);
          width: 100%;
        }
      }
    }

    .tabs-content {
      @include tabs-content;
      border: 0;
    }
  }
}

@include breakpoint(medium only) {
  .how-it-work {
    .images-place {
      // height: rem-calc(515);
    }

    .step1-images {
      height: rem-calc(448);
      margin: 0 auto;
    }

    .step2-images {
      height: rem-calc(463);
      margin: 0 auto;
    }

    .step3-images {
      height: rem-calc(511);
      margin: 0 auto;
    }
  }
}

@include breakpoint(large up) {
  .how-it-work {
    padding-top: rem-calc(60);

    .content-container {
      // @include xy-grid;

      padding: 0;
    }

    .section-label,
    .section-title {
      text-align: left;
    }

    .section-title {
      margin-bottom: rem-calc(40);
    }

    .tabs-wrapper {
      @include xy-grid;
      justify-content: flex-end;
      padding-left: rem-calc(25);
    }

    .tabs {
      @include tabs-container-vertical;
      @include xy-cell-base(shrink);
      width: rem-calc(60);
      margin: rem-calc(0 30 0 0);
      border: 0;
      border-right: 1px solid #e9ebf8;
    }

    .tabs-title {
      margin-bottom: rem-calc(10);

      a {
        padding-right: rem-calc(16);
        text-align: right;

        &:before {
          top: 0;
          right: rem-calc(-1);
          height: 100%;
          width: rem-calc(1);
        }
      }
    }

    .tabs-content {
      @include tabs-content-vertical;
      @include xy-cell-base;
      // max-width: rem-calc(1445);
      // width: calc(50% + 490px);
      width: calc(100% - 90px);
      border: 0;
    }

    .tabs-panel {
      padding-top: rem-calc(5);
      padding-bottom: 0;
    }

    .text {
      width: calc(50% - 120px);
      // max-width: rem-calc(480);
    }

    .images-place {
      @include xy-cell-base(auto);
      @include xy-grid;
      justify-content: flex-end;
      align-items: flex-end;
      height: rem-calc(335);
      width: 50%;
    }

    .slider-arrows {
      width: 100%;
      margin-top: rem-calc(-50);
      padding-left: rem-calc(50);
    }

    .title {
      font: {
        size: rem-calc(20);
      }
    }
  }
}

@include breakpoint(1245px up) {
  .how-it-work {
    .tabs-content {
      width: calc(50% + 520px);
    }
  }
}

@include breakpoint(xxlarge up) {
  .how-it-work {
    .slider-arrows {
      padding-right: rem-calc(15);
      padding-left: rem-calc(65);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2547";