.intent-form {
  .additional-info-button {
    @extend %with-cursor-without-outline;
    margin-left: rem-calc(10);

    .icon {
      position: relative;
      bottom: rem-calc(-3);
      height: rem-calc(16);
      width: rem-calc(16);
      fill: $purple;
    }
  }

  .additional-info-dropdown {
    @include dropdown-container;
    border: 0;
    border-radius: rem-calc(8);
    box-shadow: 0 4px 16px rgba(229, 229, 229, 0.8);
    top: rem-calc(15);

    .close-button {
      @include universal-close-button($purple, rem-calc(20), rem-calc(26));
      top: 0;
      right: 0;
      border-radius: rem-calc(5);

      .icon {
        border-radius: rem-calc(5);
        background: #f3f5fd;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2439";