.safety {
  margin-bottom: rem-calc(50);

  .content-container {
    @include xy-grid-container;
  }

  .list {
    @include xy-grid;
    justify-content: center;
    align-items: flex-start;
    margin: rem-calc(0 -15);
    padding: 0;
    list-style: none;
  }

  .item {
    @include xy-cell-base;
    width: rem-calc(290);
    margin: rem-calc(30 15 0);
    text-align: center;

    .image {
      display: block;
      margin-bottom: rem-calc(20);

      img,
      source {
        width: 100%;
      }
    }

    .title {
      margin-bottom: rem-calc(10);
      color: $black;
      font: {
        size: rem-calc(18);
        weight: 500;
      }
    }
  }
}

@include breakpoint(medium up) {
  .safety {
    .item {
      font: {
        size: rem-calc(16);
      }

      .title {
        font: {
          size: rem-calc(20);
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .safety {
    margin-bottom: rem-calc(90);

    .section-title {
      margin-bottom: rem-calc(50);
    }

    .list {
      margin-right: rem-calc(-25);
      margin-left: rem-calc(-25);
    }

    .item {
      width: calc(33.333% - 50px);
      margin: rem-calc(0 25);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2581";