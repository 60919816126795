.intent-form {
  .valuable-specifics {
    &.jewelry-specifics {
      .buttons-fieldset {
        .button.other-jewelry {
          label {
            padding-left: rem-calc(10);
            color: $purple;
            font: {
              size: rem-calc(11);
            }
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@include breakpoint(medium up) {
  .intent-form {
    .valuable-specifics {
      &.jewelry-specifics {
        .buttons-fieldset {
          .button.other-jewelry,
          .field {
            width: rem-calc(260);
          }
        }
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .intent-form {
    .valuable-specifics {
      &.jewelry-specifics {
        .buttons-fieldset {
          .button {
            width: auto;
            margin-right: rem-calc(25);
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2479";