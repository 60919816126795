
@mixin radio-with-icon {
  // @extend %with-cursor-without-outline;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: rem-calc(-9999);
  }

  label {
    position: relative;
    display: block;
    // height: rem-calc(108);
    // width: rem-calc(108);
    margin: 0;
    // padding: rem-calc(68 10 10);
    background: $white;
    // border: 1px solid #edeef1;
    // border-radius: rem-calc(8);
    // text-align: center;
    line-height: 1;
    color: $medium-gray;
    // text-transform: uppercase;
    cursor: pointer;
    // transition: color 0.25s ease-in-out;

    .icon {
      // @include horizontal-center;
      // top: rem-calc(20);
      fill: #cccdd0;
    }

    &:focus-visible {
      outline: 0;
      box-shadow: 0 0 10px rgba(229, 229, 229, 0.8);
    }

    &:hover {
      // box-shadow: 0 0 16px rgba(229, 229, 229, 0.8);
      color: $purple;

      .icon {
        fill: currentColor;
      }
    }
  }

  [type="radio"]:checked + label {
    // background: $purple;
    // box-shadow: none;
    // color: $white;
    cursor: default;

    .icon {
      fill: currentColor;
    }
  }

  &.is-active {
    label {
      cursor: default;
    }

    .icon {
      fill: currentColor;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2237";