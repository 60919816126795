.promotion-banner {
  margin-bottom: rem-calc(55);

  .description {
    order: 1;
    margin-bottom: rem-calc(15);
  }

  .image-container {
    order: 3;
    min-height: rem-calc(204);
  }

  .bullet-list {
    @include xy-cell-base;
    @include bullet-list;
    @include xy-grid;
    order: 2;
    justify-content: center;
    width: calc(100% + 20px);
    margin: rem-calc(0 -10 20);
    text-align: left;

    li {
      @include xy-cell-base;
      width: rem-calc(145);
      margin: rem-calc(0 5 7);
      padding-left: rem-calc(25);

      &::before {
        background-color: $medium-green;
      }
    }
  }

  .text {
    margin-bottom: rem-calc(25);
  }

  .buttons-place {
    @include xy-grid;
    justify-content: center;
    align-items: center;
  }

  .video-button {
    @extend %with-cursor-without-outline;
    padding-left: rem-calc(55);
    position: relative;
    margin: rem-calc(25 20);

    color: $medium-green;
    font: {
      size: rem-calc(13);
      weight: 700;
    }
    text-transform: uppercase;

    .icon-container {
      @include vertical-center;
      @include pulse;
      left: rem-calc(10);
      height: rem-calc(34);
      width: rem-calc(34);
      background: $medium-green;
      border: 5px solid rgba($medium-green, 0.2);
      border-radius: 50%;
      background-clip: padding-box;

      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        z-index: -1;
        height: rem-calc(34);
        width: rem-calc(34);
        margin: rem-calc(-17 0 0 -17);
        background: rgba($medium-green, 0.2);
        border-radius: 50%;
      }
    }

    .icon {
      @include absolute-center;
      color: white;
      height: rem-calc(7);
      width: rem-calc(6);
      margin-left: rem-calc(1);
    }
  }
}

@include breakpoint(425px up) {
  .promotion-banner {
    .bullet-list {
      li {
        width: rem-calc(185);
        padding-left: rem-calc(35);
      }
    }
  }
}

@include breakpoint(medium up) {
  .promotion-banner {

    .bullet-list {
      font: {
        size: rem-calc(20);
      }

      li {
        width: rem-calc(250);
        margin-bottom: rem-calc(3);

        &::before {
          top: rem-calc(7);
        }
      }
    }

    .text {
      margin-bottom: rem-calc(15);
    }
  }
}

@include breakpoint(large up) {
  .promotion-banner {
    padding-top: rem-calc(50);

    .content-container {
      justify-content: flex-end;
      padding-right: 0;
      padding-left: 0;
    }

    .image-container {
      order: 2;
      justify-content: flex-start;
      width: calc(50% + 90px);


      .image {
        max-width: rem-calc(930);
      }
    }

    .description {
      width: rem-calc(415);
      padding-top: rem-calc(50);
      padding-left: rem-calc(25);
      text-align: left;
    }

    .bullet-list {
      @include xy-grid-container;
      order: 3;
      justify-self: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0;

      li {
        width: 27%;
      }
    }

    .section-label,
    .section-title {
      text-align: left;
    }

    .buttons-place {
      justify-content: flex-start;
    }

    .video-button {
      margin-right: 0;
    }
  }
}

@include breakpoint(xlarge up) {
  .promotion-banner {
    .content-container{
      @include xy-grid-container(rem-calc(1730), 0);
    }

    .image-container {
      width: calc(50% + 85px);
    }

    .description {
      width: rem-calc(525);
      padding-top: rem-calc(70);
    }

    .section-title {
      margin-bottom: rem-calc(35);
      font: {
        size: rem-calc(50);
      }
    }

    .text {
      max-width: rem-calc(400);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2562";