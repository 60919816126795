.intent-form {
  .intent-amount {
    &::before {
      content: '$';
      position: absolute;
      top: rem-calc(29);
      left: rem-calc(13);
      z-index: 1;
      color: $black;
      line-height: rem-calc(21);
      font: {
        size: rem-calc(14);
      }
    }

    .input-label {
      color: $black;
      line-height: 1;
      font: {
        size: rem-calc(14);
        weight: 400;
      }
    }

    .input-field {
      padding-left: rem-calc(22);
    }
  }
}

@include breakpoint(small only) {
  .intent-form {
    .intent-amount {
      &::before {
        font: {
          size: rem-calc(16);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2445";