.page-overview {
  font: {
    size: rem-calc(14);
  }

  .content-container {
    @include xy-grid-container;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $medium-gray;
    font: {
      size: rem-calc(14);
    }
    text-transform: uppercase;
  }
}

;@import "sass-embedded-legacy-load-done:2381";