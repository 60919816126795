.section-label,
.section-title {
  text-align: center;
  font: {
    weight: 700;
  }
}

.section-label {
  margin-bottom: rem-calc(10);
  color: $purple;
  line-height: 1.2;
  font: {
    size: rem-calc(14);
  }
  text-transform: uppercase;
}

.section-title {
  color: $black;
  line-height: 1.3;
  font: {
    size: rem-calc(22);
  }
}

@include breakpoint(medium up) {
  .section-title {
    font: {
      size: rem-calc(32);
    }
  }
}
;@import "sass-embedded-legacy-load-done:2392";