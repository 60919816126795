@mixin required-field {
  &.required-field {
    .input-label {
      &::after {
        content: '*';
        color: $alert-color;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2257";