.benefits {
  @include xy-grid-container(rem-calc(1180));
  position: relative;
  z-index: 1;
  padding: rem-calc(36 0 10);
  background: linear-gradient(90deg, #27272E 0%, #484854 100%);
  border-top: 4px solid $green;
  border-radius: 8px;
  color: #cccdD0;

  .list {
    @include xy-grid;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .item {
    @include xy-cell-base;
    width: rem-calc(280);
    margin-bottom: rem-calc(30);
    text-align: center;

    .icon {
      display: block;
      margin: 0 auto;
      margin-bottom: rem-calc(10);
    }

    .title {
      margin-bottom: rem-calc(15);
      color: $white;
      line-height: 1.3;
      font: {
        size: rem-calc(18);
        weight: 500;
      }
    }

    // &:nth-child(1) {
    //   .icon {
    //     margin-top: rem-calc(12);
    //     margin-bottom: rem-calc(22);
    //   }
    // }
  }
}

@include breakpoint(medium down) {
  .benefits {
    margin-right: rem-calc(10);
    margin-left: rem-calc(10);
  }
}

@include breakpoint(medium up) {
  .benefits {
    .item {
      margin-right: rem-calc(20);
      margin-left: rem-calc(20);
    }
  }
}

@include breakpoint(large only) {
  .benefits {
    width: calc(100% - 50px);
  }
}

@include breakpoint(large up) {
  .benefits {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: rem-calc(36);

    // .item {
    //   margin: 0;

    //   // &:nth-child(1) {
    //   //   width: rem-calc(250);
    //   // }

    //   // &:nth-child(2) {
    //   //   width: rem-calc(280);
    //   //   margin: rem-calc(0 40);
    //   // }

    //   // &:nth-child(3) {
    //   //   width: rem-calc(345);
    //   // }
    // }
  }
}

// @include breakpoint(xlarge up) {
//   .benefits {
//     .item {
//       &:nth-child(2) {
//         margin-right: rem-calc(70);
//         margin-left: rem-calc(90);
//       }
//     }
//   }
// }

;@import "sass-embedded-legacy-load-done:2328";